import React from 'react'
import Container from '@material-ui/core/Container'
import { Link } from 'gatsby'
import PhoneIcon from '@material-ui/icons/Phone'
import RoomIcon from '@material-ui/icons/Room'
import MailIcon from '@material-ui/icons/Mail'
import DomainIcon from '@material-ui/icons/Domain'
import LogoFooter from '../../elements/Logo/LogoFooter'
import SocialLink from './SocialLink'
import * as S from './Footer.styles'

function Copyright() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="footer-top-items" style={{ maxwidth: '50%' }}>
          <LogoFooter className="logo-footer" />
          <p>
            Il nome ING GROUP riflette la doppia anima della società ed assume due significati diversi: la tecnica ed il
            rigore dell’ingegneria e la creatività dei progetti.
          </p>
          <div className="social-menu-footer">{<SocialLink className="social-icon" />}</div>
        </div>
        <div className="footer-top-items footer-link">
          <h4>LINK</h4>
          <Link className="footer-menu-item" to="/">
            Home
          </Link>
          <Link className="footer-menu-item" to="/chi-siamo">
            Chi Siamo
          </Link>
          <Link className="footer-menu-item" to="/privacy-cookie-policy">
            Privacy &amp; Cookie Policy
          </Link>
          <Link className="footer-menu-item" to="/contatti">
            Contatti
          </Link>
        </div>
        <div className="footer-top-items">
          <h4>CONTATTI</h4>
          <p>
            <RoomIcon className="icon-style" />
            Via Milite Ignoto, 20 80078 Pozzuoli (NA)
          </p>
          <p>
            <PhoneIcon className="icon-style" />
            Tel : &nbsp;081.19369982/3 - Pozzuoli (NA)
          </p>
          <p>
            <PhoneIcon className="icon-style" />
            Tel : &nbsp;0298650575 - Milano (MI)
          </p>
          <p>
            <PhoneIcon className="icon-style" />
            Tel : &nbsp;04441500119 - Torri Di Quartesolo (VI)
          </p>
          <p>
            <MailIcon className="icon-style" />
            Email : &nbsp;info@ing-group.it
          </p>
          <p>
            <DomainIcon className="icon-style" />
            P.IVA : &nbsp;06836631215
          </p>
        </div>
      </div>
      {/* <div className="footer-bottom">
        <div className="footer-bottom-text">
          <span>
            Copyright 2021 powered by&nbsp;
            <a href="https://biztems.it" target="_blank" rel="noopener noreferrer">
              Biztems
            </a>
          </span>
        </div>
      </div> */}
    </footer>
  )
}

export default function StickyFooter() {
  return (
    <S.Footer>
      <Container>
        <Copyright />
      </Container>
    </S.Footer>
  )
}
